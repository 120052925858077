import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/macOS/NavButtons';
import ForumBox from 'components/Software/macOS/Sighthound/ForumBox';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "macOS Software for your INSTAR IP Camera",
  "path": "/Software/macOS/Sighthound/",
  "dateChanged": "2017-12-05",
  "author": "Mike Polinowski",
  "excerpt": "The macOS app Sighthound for your iMac or macBook. Control your camera from your computer and harness the computing power for advanced features.",
  "image": "./P_SearchThumb_Sighthound.png",
  "social": "/images/Search/P_SearchThumb_Sighthound.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-Sighthound_white.webp",
  "chapter": "Software"
};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const EuiSpacer = makeShortcode("EuiSpacer");
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='macOS Software for your INSTAR IP Camera' dateChanged='2017-12-05' author='Mike Polinowski' tag='INSTAR IP Camera' description='The macOS app Sighthound for your iMac or macBook. Control your camera from your computer and harness the computing power for advanced features.' image='/images/Search/P_SearchThumb_Sighthound.png' twitter='/images/Search/P_SearchThumb_Sighthound.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/macOS/Sighthound/' locationFR='/fr/Software/macOS/Sighthound/' crumbLabel="Sighthound" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h1>
    <h2 {...{
      "id": "macos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#macos",
        "aria-label": "macos permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`macOS`}</h2>
    <h3 {...{
      "id": "sighthound",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#sighthound",
        "aria-label": "sighthound permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Sighthound`}</h3>
    <p>{`Sighthound Video is surveillance software for homes and businesses that has been trained to recognize people and objects. Most off‐the‐shelf security applications available in consumer channels use motion detection to determine if moving objects are present in video. Motion detection analyses how many pixels have changed between frames. When enough pixels change, video is recorded and/or the customer is notified of a “motion event.”`}</p>
    <p>{`Unfortunately, pixels can also change with clouds passing overhead, leaves swaying in the wind, or flickering lights. More advanced systems let customers ignore selected regions in the video, or set sensitivity levels. Nevertheless, motion detection often generates an unacceptable number of false alerts. Computer vision isn’t based on pixels moving, it works by learning what certain objects look like, in much the same way as a child learns. Computer vision can detect, track and recognize objects in a way that motion detectors can never achieve.`}</p>
    <p>{`In order to integrate your INSTAR IP camera in `}<a href="http://www.sighthound.com/" target="_blank" rel="noopener noreferrer">{`Sighthound`}</a>{` we recommend you to download the latest version from `}<a href="http://www.sighthound.com/download" target="_blank" rel="noopener noreferrer">{`here`}</a>{`.`}</p>
    <h3 {...{
      "id": "add-a-camera",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#add-a-camera",
        "aria-label": "add a camera permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Add a Camera`}</h3>
    <p>{`After you installed the newest version of Sighthound please open the program, click the to add a new camera and choose your INSTAR camera model.`}</p>
    <p><img parentName="p" {...{
        "src": "./SighthoundSighthound_01.png",
        "alt": "Sighthound for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Click on `}<strong parentName="p">{`Add`}</strong>{` to open the Camera Setup dialogue.`}</p>
    <p><img parentName="p" {...{
        "src": "./SighthoundSighthound_02.png",
        "alt": "Sighthound for your INSTAR IP Camera"
      }}></img></p>
    <p>{`You should be able to see a snapshot from your camera here - then click `}<strong parentName="p">{`Next`}</strong>{`.`}</p>
    <p><img parentName="p" {...{
        "src": "./SighthoundSighthound_03.png",
        "alt": "Sighthound for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Give your camera a name - then click `}<strong parentName="p">{`Next`}</strong>{`.`}</p>
    <h3 {...{
      "id": "motion-detection",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#motion-detection",
        "aria-label": "motion detection permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Motion Detection`}</h3>
    <p>{`To activate the motion detection, click the camera name and choose `}<strong parentName="p">{`New Rule`}</strong>{`:`}</p>
    <p><img parentName="p" {...{
        "src": "./SighthoundSighthound_04.png",
        "alt": "Sighthound for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Click on `}<strong parentName="p">{`New Rule`}</strong>{` to open the Rule Editor dialogue.`}</p>
    <h4 {...{
      "id": "step-1",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#step-1",
        "aria-label": "step 1 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 1`}</h4>
    <p>{`Click the Object block labelled `}<strong parentName="p">{`Look for`}</strong>{`: to display a drop-down menu to select the object you want to find. You can choose People, Unknown objects (such as vehicles or pets), or Any objects (People and Unknown objects). You can also select "Ignore objects smaller than..." to filter out background objects that are never larger than a certain number of pixels. If an object approaches from the distance and grows large, however, the portions of the video where it is small will also be included.`}</p>
    <p><img parentName="p" {...{
        "src": "./SighthoundSighthound_05.png",
        "alt": "Sighthound for your INSTAR IP Camera"
      }}></img></p>
    <h4 {...{
      "id": "step-2",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#step-2",
        "aria-label": "step 2 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 2`}</h4>
    <p>{`Click the Movement block labelled `}<strong parentName="p">{`That are`}</strong>{`: to display options for selecting where in the video that object is and how it is moving. Select the location and/or direction of the object you wish to find (e.g., inside or outside a region, crossing a boundary, coming in or out of a door).`}</p>
    <p><img parentName="p" {...{
        "src": "./SighthoundSighthound_06.png",
        "alt": "Sighthound for your INSTAR IP Camera"
      }}></img></p>
    <h4 {...{
      "id": "step-3",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h4" {...{
        "href": "#step-3",
        "aria-label": "step 3 permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Step 3`}</h4>
    <p>{`Click the Response block labelled `}<strong parentName="p">{`If Seen`}</strong>{`: to display options for an alarm responses and the video recording storage management.`}</p>
    <p><img parentName="p" {...{
        "src": "./SighthoundSighthound_07.png",
        "alt": "Sighthound for your INSTAR IP Camera"
      }}></img></p>
    <p>{`Select the My Computer check box to mark the clip to be saved on your computer.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9e67ef066e58f0e79c569717277239b2/00d43/Sighthound_08.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "73.91304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAAsTAAALEwEAmpwYAAADCUlEQVQoz33Ha0/bVgAGYP+vbX9j2r5N25eJ/YNuWqvRMliIE8qlSezcfIkdYvsc2+f4+HJ8bENWtHYUSpVBetlICiJpQilt1arSJiZQP+3DXj169b7cqqBUGutrtXa5aYoKrKq2qNrVj6AoQ1GBggIEGYgKFJXLrkhmTQW3SxK3Vqoqmql14LrlGhAbEJkQGwBdggi4xEKejQLHC4FDoOvbyDcANu2wqZrcQnFpkecDQkLfCwh2XdcAtodd30NB4HsEt00NYxRFIUIu8TDGyA9DGGWy6XDzfHFufg4HjLAtTLvIiaHuYS8L4m4SxcB2cvl8Q5YM08KeZ7uOqrUMAK1wo9GxufzKHb7Ib969v/Po6X5/2OsP/3o+GZ+9Pz19s/Ngt2OBuYX5RZ4XqqKkyLV6XW9rNsJtP6uuO9wvS8v5Ar+3u/dyPH179np8cnJ+9uri738uLi72nzwzACwLlYbUrFTFb7+b+eqbr3+4/qPU0nV/s6zZ3M1c4adbs9u/7xwfHp+fno9OxoPD4WAwfPf2/X7/qQFgxzSCMPQIyRf4z7/84pPPPl3IF/Rwa1WFXLmhNhVp+/6DwZ+DyWg6GByNR5OzyemHdx/+OHhi2bYsy6ZlWpZVbzTyheLMzMxqWZC8u7cVmxNkvaW3dnf2pifT09H06PD5i9F0+uLl6+mrR70D1yOmYRrAhSiIKMvS7Ndul4SsYqVLssPdqatKS/3t3navd/Bwr3d0PHn4+Hj38bDff7Z1b5sEoe8HyPNJQMOIxnGcpakX0DUj5SWHW6vJmq6GlFKWsCRb1pe/F641QD1NNyljEY0x9pCLIADIcR3bAZZlY3+5k+UaDrdUbQuSFkU0S9OMJabWKDVXsKlvZBsJY5TGlNKIfszliCKXRHw7/bnmcDfV2oq2TmmSpkmSJAzhCNrM868+i6/CYpawmLGYxXHKYkSiXCu5Jdrcahvzgua4ge9TQiLiXyH/hbwIugFwfOgQDfjzWne2irkF0VqsgZxo/L/Zsnm9ZN0omVesGxXnWlH+F9K8dJVUmIOXAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e67ef066e58f0e79c569717277239b2/e4706/Sighthound_08.avif 230w", "/en/static/9e67ef066e58f0e79c569717277239b2/d1af7/Sighthound_08.avif 460w", "/en/static/9e67ef066e58f0e79c569717277239b2/7f308/Sighthound_08.avif 920w", "/en/static/9e67ef066e58f0e79c569717277239b2/445fc/Sighthound_08.avif 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9e67ef066e58f0e79c569717277239b2/a0b58/Sighthound_08.webp 230w", "/en/static/9e67ef066e58f0e79c569717277239b2/bc10c/Sighthound_08.webp 460w", "/en/static/9e67ef066e58f0e79c569717277239b2/966d8/Sighthound_08.webp 920w", "/en/static/9e67ef066e58f0e79c569717277239b2/a5d4d/Sighthound_08.webp 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9e67ef066e58f0e79c569717277239b2/81c8e/Sighthound_08.png 230w", "/en/static/9e67ef066e58f0e79c569717277239b2/08a84/Sighthound_08.png 460w", "/en/static/9e67ef066e58f0e79c569717277239b2/c0255/Sighthound_08.png 920w", "/en/static/9e67ef066e58f0e79c569717277239b2/00d43/Sighthound_08.png 1000w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9e67ef066e58f0e79c569717277239b2/c0255/Sighthound_08.png",
              "alt": "Sighthound for your INSTAR IP Camera",
              "title": "Sighthound for your INSTAR IP Camera",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Define additional alarm responses - like an email alert.`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ForumBox mdxType="ForumBox" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      